<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333">银行卡列表</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item>银行卡列表</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <div
        class="pb-15 pt-30 border-bottom border-EEEEEE font-14 flex align-center justify-between"
        v-if="type == 1"
      >
        <div class="font-weight-bolder">我的卡{{ info.length }}张</div>
        <div class="text-E7524C cursorp" @click="gocardbinding">+ 添加银行卡</div>
      </div>
      <div
        class="pb-15 pt-30 border-bottom border-EEEEEE font-14 flex align-center justify-between"
        v-else
      >
        <div class="font-weight-bolder">我的卡{{ info.length }}张</div>
        <div class="text-E7524C cursorp" @click="gocardbinding2">+ 添加银行卡</div>
      </div>
      <!--内容  -->
      <div>
        <div
          class="border-bottom border-EEEEEE flex justify-between pt-35 pb-25"
          v-for="(item, index) in info"
          :key="index"
        >
          <div class="flex align-center">
            <!-- <img
              :src="item.avatar"
              width="3.3125rem"
              height="3.3125rem"
              class="circle mr-20"
              alt=""
            /> -->
            <el-image
              class="circle mr-20"
              style="width: 3.3125rem; height: 3.3125rem"
              :src="item.avatar"
              fit="cover"
            ></el-image>
            <div class="font-14">
              <div class="mb-10">
                {{ item.username }}
              </div>
              <div class="text-B5B5B5">
                VIP时间：{{ item.vip_start_time
                }}<span v-if="item.vip_start_time && item.vip_end_time">~</span
                >{{ item.vip_end_time }}
              </div>
            </div>
          </div>
          <div class="font-14 text-999999">用户ID{{ item.id }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      info: [],
      type: 1,
    };
  },

  mounted() {
    this.getdata();
    this.is_realname();
  },
  methods: {
    gocardbinding2() {
      this.$router.push({
        path: "/cardbinding2",
        query: {type: "cardbinding2",},
      });
    },
    // 跳转绑定银行卡
    gocardbinding() {
      this.$router.push({
        path: "/cardbinding",
        query: {type: "cardbinding",},
      });
    },
    // 判断是否实名
    is_realname() {
      let that = this;
      req
        .post("center/queryUser", {})
        .then((res) => {
          if (res.code != 200) {
            // that.$message.error(res.msg);
            return;
          }
          that.type = 2;
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
    // 获取数据
    getdata() {
      let that = this;
      req
        .post("auth/bankList", {})
        .then((res) => {
          if (res.code != 200) {
            that.$message.error(res.msg);
            return;
          }
          if (res.data.fast_cards && res.data.fast_cards.length) {
            that.info = res.data.fast_cards;
          }
        })
        .catch((err) => {
          console.log(err);
          that.$message.error(err.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
}
</style>
